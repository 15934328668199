interface FetchWrapperConfig {
  baseUrl?: string;
  defaultHeaders?: Record<string, string>;
  onErrorResponse?: (error: ErrorResponse) => void;
}

interface RequestConfig {
  headers?: Record<string, string>;
}

enum HTTPMethod {
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
}

/**
 * A very simple polyfill wrapper around the fetch API to provide axios-like functionality,
 * since service workers do not have XMLHttpRequest support and Axios won't work.
 * This wrapper helps:
 * - automatically set authentication headers for each API call.
 * - centralized point for error handling and logging.
 * - set a baseUrl for each API call.
 * - handle 401 errors and refresh the token
 */
export class FetchWrapper {
  /**
   * Default headers for the fetch requests.
   */
  private readonly defaultHeaders: Record<string, string> = {};
  private readonly baseUrl: string | null = null;
  private readonly onErrorResponse?: (error: ErrorResponse) => void;

  public constructor(config: FetchWrapperConfig = {}) {
    if (config.baseUrl) {
      this.baseUrl = config.baseUrl;
    }
    if (config.defaultHeaders) {
      this.defaultHeaders = config.defaultHeaders;
    }
    if (config.onErrorResponse) {
      this.onErrorResponse = config.onErrorResponse;
    }
  }

  public async get(url: string, config: RequestConfig = {}) {
    return this.request(HTTPMethod.GET, url, null, config);
  }

  public async put(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.PUT, url, body, config);
  }

  public async post(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.POST, url, body, config);
  }

  public async delete(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.DELETE, url, body, config);
  }

  public async patch(url: string, body: unknown = null, config: RequestConfig = {}) {
    return this.request(HTTPMethod.PATCH, url, body, config);
  }

  public async request(method: string, url: string, body: unknown = null, config: RequestConfig = {}) {
    const requestOptions = {
      method,
      headers: { ...this.defaultHeaders, ...config.headers },
      body: body ? JSON.stringify(body) : null,
      //Todo: Can extend this to accept form data later.
    };
    let response: Response;
    try {
      response = await fetch(this.baseUrl ? this.baseUrl + url : url, requestOptions);
    } catch (e) {
      throw new FetchWrapperError(method, url, e);
    }
    if (response.ok) {
      return response.json();
    } else {
      const errorResponse: ErrorResponse = { status: response.status, body: null };
      try {
        errorResponse.body = await response.json();
      } catch (e) {
        errorResponse.body = null;
      }
      if (this.onErrorResponse) {
        this.onErrorResponse(errorResponse);
      }
      throw new FetchWrapperError(method, url, errorResponse);
    }
  }

  public addDefaultHeader(key: string, value: string): void {
    this.defaultHeaders[key] = value;
  }

  public removeDefaultHeader(key: string): void {
    delete this.defaultHeaders[key];
  }
}

export interface ErrorResponse {
  status: number;
  body: any;
}

function isErrorResponse(error: unknown): error is ErrorResponse {
  return typeof error === "object" && error !== null && "status" in error && "body" in error;
}

export class FetchWrapperError extends Error {
  constructor(
    public readonly method: string,
    public readonly url: string,
    public readonly error: unknown | ErrorResponse,
  ) {
    const message =
      error instanceof Error ? error.message : isErrorResponse(error) ? JSON.stringify(error) : String(error);
    super(message);
  }
}
