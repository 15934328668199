export const isOfflineError = (e: any) => {
  const message = e instanceof Error ? e.message : String(e);
  return (
    message.includes("Failed to fetch") ||
    message.includes("Load failed") ||
    message.includes("connection was lost") ||
    message.includes("connection appears to be offline")
  );
};

const ABORT_MSG_REGEX = /^Attempt to .* from database without an in-progress transaction$/;
/**
 * Returns true if the error message comes from an aborted indexedDB transaction.
 */
export const isAbortedTransactionError = (message: string) => {
  return ABORT_MSG_REGEX.test(message);
};

export const isTimeoutError = (message: string) => {
  return message.includes("Task timed out");
};
