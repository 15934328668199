export const Logo = ({ fill = "black", height = "64" }) => {
  const width = +height * 5.078125;

  return (
    <svg width={width} height={height} viewBox={`0 0 325 64`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1031_16889)">
        <path d="M0 63.1118V2.56995H7.72986V63.1118H0Z" fill={fill} style={{ fill: fill, fillOpacity: 1 }} />
        <path
          d="M15.3762 41.1073C15.3762 36.9267 16.247 33.1132 18.0006 29.6432C19.7422 26.1732 22.1518 23.39 25.2175 21.3057C28.2832 19.2213 31.7902 18.1791 35.7387 18.1791C39.3412 18.1791 42.5858 19.1147 45.4487 20.9977C48.3116 22.8689 50.5423 25.1428 52.1527 27.8193V0.876343H59.7156V54.5965C59.7156 55.5085 59.9064 56.1717 60.2762 56.598C60.646 57.0244 61.2663 57.2612 62.1252 57.3205V63.1236C60.6937 63.3486 59.5724 63.467 58.7732 63.467C57.2821 63.467 55.9818 62.9578 54.8605 61.9274C53.7392 60.9089 53.1905 59.772 53.1905 58.5166V54.7624C51.4131 57.6047 49.0393 59.8549 46.0571 61.5011C43.0749 63.1473 39.9853 63.9763 36.7765 63.9763C33.5676 63.9763 30.8359 63.3723 28.2355 62.1524C25.6231 60.9444 23.3685 59.2627 21.448 57.1191C19.5274 54.9755 18.0363 52.5359 16.9866 49.8001C15.9011 47.0525 15.3762 44.1628 15.3762 41.1073ZM52.1288 47.6091V34.9489C51.437 33.0303 50.3276 31.3012 48.7768 29.7616C47.2261 28.2102 45.4964 26.9785 43.5759 26.0429C41.6554 25.1191 39.7587 24.6454 37.862 24.6454C35.6313 24.6454 33.6153 25.1073 31.8022 26.0429C30.0009 26.9666 28.4502 28.2102 27.1619 29.7616C25.8736 31.3131 24.8835 33.0658 24.2035 35.0318C23.5117 36.9977 23.1777 39.0584 23.1777 41.1902C23.1777 43.3219 23.5594 45.4655 24.3347 47.4315C25.1101 49.3974 26.1956 51.1502 27.6032 52.6661C29.0108 54.182 30.6331 55.3663 32.494 56.2072C34.3549 57.0481 36.3709 57.4744 38.5539 57.4744C39.9257 57.4744 41.3452 57.2257 42.8005 56.7165C44.2558 56.2072 45.6515 55.5085 46.9637 54.6084C48.2758 53.7083 49.3971 52.6543 50.3157 51.4463C51.2461 50.2383 51.8425 48.9592 52.1288 47.6091Z"
          fill={fill}
          style={{ fill: fill, fillOpacity: 1 }}
        />
        <path
          d="M90.0147 63.9644C86.6388 63.9644 83.5612 63.3604 80.7818 62.1406C78.0024 60.9326 75.6047 59.2627 73.5648 57.1428C71.537 55.0229 69.9624 52.5832 68.841 49.8238C67.7197 47.0643 67.171 44.1273 67.171 41.0244C67.171 36.8438 68.1492 33.0421 70.0936 29.5958C72.038 26.1495 74.7458 23.39 78.2051 21.3056C81.6645 19.2213 85.6368 18.1791 90.0982 18.1791C94.5595 18.1791 98.5557 19.2331 101.908 21.353C105.26 23.4729 107.86 26.2324 109.721 29.655C111.582 33.0658 112.512 36.7609 112.512 40.7046C112.512 41.2731 112.5 41.8179 112.465 42.3508C112.441 42.8837 112.393 43.2982 112.333 43.5825H75.1395C75.3661 46.4485 76.1892 48.9948 77.5849 51.2094C78.9925 53.4241 80.8056 55.1769 83.0363 56.4322C85.267 57.6994 87.6766 58.3271 90.2532 58.3271C92.9969 58.3271 95.5973 57.6402 98.0308 56.2783C100.464 54.9163 102.134 53.128 103.053 50.9015L109.578 52.6898C108.6 54.8453 107.156 56.7875 105.236 58.4929C103.315 60.1983 101.061 61.5366 98.4483 62.4959C95.8598 63.4789 93.0446 63.9644 90.0147 63.9644ZM74.8889 38.3834H105.462C105.236 35.5173 104.401 32.9948 102.969 30.8038C101.538 28.6128 99.7008 26.8956 97.4702 25.6639C95.2395 24.4322 92.7702 23.8045 90.0862 23.8045C87.4022 23.8045 85.0165 24.4204 82.7858 25.6639C80.5551 26.8956 78.73 28.6128 77.3344 30.8038C75.9387 32.9948 75.1156 35.5173 74.8889 38.3834Z"
          fill={fill}
          style={{ fill: fill, fillOpacity: 1 }}
        />
        <path
          d="M115.256 50.3212C115.256 47.4907 116.067 45.051 117.701 42.9903C119.336 40.9296 121.578 39.3308 124.441 38.2057C127.304 37.0806 130.62 36.5122 134.402 36.5122C136.406 36.5122 138.529 36.6661 140.76 36.9859C142.99 37.2938 144.971 37.7675 146.688 38.3952V35.1739C146.688 31.8105 145.662 29.1576 143.599 27.2272C141.535 25.2968 138.613 24.3256 134.843 24.3256C132.386 24.3256 130.024 24.7638 127.757 25.6521C125.491 26.5284 123.105 27.7957 120.588 29.4418L117.845 24.1598C120.767 22.1702 123.69 20.678 126.6 19.6831C129.523 18.6883 132.553 18.1909 135.702 18.1909C141.428 18.1909 145.949 19.7542 149.277 22.8808C152.593 26.0073 154.263 30.3656 154.263 35.9437V54.6202C154.263 55.5203 154.454 56.1835 154.824 56.6099C155.194 57.0362 155.814 57.2731 156.673 57.3323V63.1354C155.933 63.2538 155.289 63.3367 154.74 63.3959C154.192 63.4552 153.75 63.4788 153.404 63.4788C151.627 63.4788 150.303 62.9933 149.408 62.034C148.525 61.0629 148.024 60.0444 147.905 58.9666L147.738 56.148C145.794 58.6469 143.241 60.5773 140.092 61.9511C136.943 63.313 133.817 63.9999 130.728 63.9999C127.638 63.9999 125.085 63.3959 122.735 62.1761C120.385 60.9563 118.56 59.3219 117.236 57.2612C115.912 55.165 115.256 52.8675 115.256 50.3212ZM144.458 53.4359C145.15 52.6543 145.686 51.8489 146.092 51.0318C146.497 50.2146 146.688 49.5277 146.688 48.9592V43.4759C144.911 42.8008 143.05 42.2797 141.106 41.9126C139.161 41.5455 137.241 41.3678 135.356 41.3678C131.515 41.3678 128.413 42.1139 126.04 43.6062C123.666 45.0984 122.473 47.1354 122.473 49.729C122.473 51.1384 122.855 52.5003 123.63 53.8267C124.405 55.1532 125.551 56.2309 127.065 57.0836C128.58 57.9244 130.453 58.3508 132.696 58.3508C134.938 58.3508 137.277 57.8889 139.4 56.9533C141.511 56.0177 143.205 54.8452 144.458 53.4359Z"
          fill={fill}
          style={{ fill: fill, fillOpacity: 1 }}
        />
        <path
          d="M201.06 63.9645C198.137 63.9645 195.835 63.1354 194.141 61.4774C192.447 59.8194 191.612 57.51 191.612 54.5374V16C191.612 14.5196 191.35 13.0392 190.837 11.5825C190.324 10.1258 189.429 8.91784 188.177 7.94671C186.912 6.97557 185.147 6.49001 182.856 6.49001C180.793 6.49001 179.111 6.97557 177.787 7.93486C176.475 8.90599 175.496 10.2324 174.864 11.9378C174.232 13.6432 173.886 15.6092 173.838 17.8238V18.5936H182.689V24.4796H173.838V63.1118H166.275V24.4796H160.18V18.5936H166.275V17.054C166.275 13.584 166.908 10.5877 168.16 8.05329C169.425 5.51887 171.262 3.55292 173.695 2.13175C176.129 0.710585 179.147 0 182.761 0C188.26 0 192.364 1.33827 195.083 4.00296C197.803 6.6795 199.163 10.114 199.163 14.3183V51.5766C199.163 54.0163 199.509 55.5322 200.189 56.1007C200.881 56.6691 201.704 56.9534 202.682 56.9534C203.887 56.9534 205.032 56.8231 206.118 56.5744C207.203 56.3138 208.122 56.0533 208.861 55.7691L210.066 62.0933C209.267 62.4486 208.324 62.7565 207.227 63.0289C206.141 63.2894 205.068 63.5144 204.006 63.6921C202.968 63.8697 201.978 63.9645 201.06 63.9645Z"
          fill={fill}
          style={{ fill: fill, fillOpacity: 1 }}
        />
        <path
          d="M233.351 63.9644C229.975 63.9644 226.91 63.3604 224.166 62.1406C221.422 60.9326 219.037 59.2627 217.032 57.1428C215.028 55.0229 213.478 52.5832 212.392 49.8238C211.307 47.0643 210.758 44.151 210.758 41.1073C210.758 38.0636 211.307 35.0673 212.392 32.3079C213.478 29.5484 215.028 27.0969 217.032 24.9889C219.037 22.8689 221.422 21.2109 224.202 19.9911C226.981 18.7831 230.059 18.1672 233.435 18.1672C236.81 18.1672 239.793 18.7712 242.536 19.9911C245.28 21.1991 247.678 22.8689 249.705 24.9889C251.733 27.1088 253.296 29.5484 254.382 32.3079C255.467 35.0792 256.016 38.0044 256.016 41.1073C256.016 44.2102 255.467 47.0643 254.382 49.8238C253.296 52.5951 251.745 55.0347 249.741 57.1428C247.737 59.2627 245.351 60.9208 242.572 62.1406C239.805 63.3604 236.727 63.9644 233.351 63.9644ZM218.488 41.202C218.488 44.2339 219.156 46.9933 220.504 49.4685C221.852 51.9437 223.641 53.8978 225.872 55.3308C228.102 56.7638 230.596 57.4863 233.339 57.4863C236.083 57.4863 238.588 56.752 240.854 55.2953C243.121 53.8267 244.922 51.849 246.27 49.3501C247.618 46.8512 248.286 44.0799 248.286 41.0362C248.286 37.9926 247.618 35.245 246.27 32.7698C244.922 30.2945 243.121 28.3286 240.854 26.8601C238.588 25.3915 236.083 24.6691 233.339 24.6691C230.596 24.6691 228.102 25.4152 225.872 26.9074C223.641 28.3997 221.852 30.3775 220.504 32.8527C219.168 35.316 218.488 38.0991 218.488 41.202Z"
          fill={fill}
          style={{ fill: fill, fillOpacity: 1 }}
        />
        <path
          d="M317.699 18.8541H325L305.926 63.1117H299.317L290.645 42.2561L282.056 63.1117H275.448L256.385 18.8541H263.602L279.062 55.7335L286.279 37.5899L278.716 18.937H285.325L290.645 33.0066L295.965 18.937H302.574L295.106 37.5899L302.323 55.7335L317.699 18.8541Z"
          fill={fill}
          style={{ fill: fill, fillOpacity: 1 }}
        />
      </g>
      <defs>
        <clipPath id="clip0_1031_16889">
          <rect width="325" height="64" fill={fill} style={{ fill: fill, fillOpacity: 1 }} />
        </clipPath>
      </defs>
    </svg>
  );
};
