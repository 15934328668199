import { atom, getDefaultStore } from "jotai";
import { EditorView } from "prosemirror-view";
import { NoteId, UserInfo, UserSettings } from "../../shared/types";
import { ToastProps } from "../components/Toast";
import { defaultUserSettings } from "./defaults";

export const isOnlineAtom = atom(true);
export const getIsOnline = () => getDefaultStore().get(isOnlineAtom);
export const setIsOnline = (value: boolean) => getDefaultStore().set(isOnlineAtom, value);

export const sidebarUpdateAtom = atom(0);
export const isSidebarOpenAtom = atom(false);
export const disableSidebarAnimationAtom = atom(false);

export const accessTokenAtom = atom<string | null>(null);
// Used to trigger a refresh of the access token by incrementing the count.
export const requestAccessTokenUpdateAtom = atom<number>(0);

export const menuUpdate = atom(0);

export const mobileNoteIdMenuAtom = atom<null | { id: NoteId; path: string }>(null);

export const searchResultsCountAtom = atom(0);

export const isSimonSearchLoadingAtom = atom(false);

export const toastsAtom = atom<ToastProps[]>([]);

/**
 * User settings atom
 *
 * Starts with the default settings but with the `seen` fields initially set to `true` at start
 * up to avoid pestering existing users with onboarding hints.
 *
 * Once we finish loading the user's settings, we'll update the values to `false` if they haven't
 * seen the feature.
 */
export const userSettingsAtom = atom<UserSettings>({
  ...defaultUserSettings,
  seenAtSignToast: true,
  seenTildeSignToast: true,
  seenOcrToast: true,
  seenOpenAsPageHint: true,
  seenToDoToast: true,
  seenUnfurl: true,
  seenUnfurlUndo: true,
  seenOnboarding: true,
});

export const userInfoAtom = atom<UserInfo | null>(null);
export const getUserHandleOrId = () => {
  const userId = getUserId();
  const userInfo = getDefaultStore().get(userInfoAtom);
  return userInfo?.handle || userId;
};

export const wasAtLeastOneNoteAddedAtom = atom<boolean>(false);

export const notesCreatedSinceLastQueryUpdateAtom = atom<NoteId[]>([]);

export const noteListUpdatedSourceAtom = atom<"simon" | null>(null);

export const userIdAtom = atom<null | string>(null);
export const getUserId = () => getDefaultStore().get(userIdAtom);

export const userEmailAtom = atom<null | string>(null);
export const getUserEmail = () => getDefaultStore().get(userEmailAtom);

/** Atoms used by editorPage */

export const editorViewAtom = atom<null | EditorView>(null);
export const getEditorView = () => getDefaultStore().get(editorViewAtom);

export const isLoadedAtom = atom(false);
export const isKeyboardVisibleAtom = atom(false);
export const mobileCloseKeyboardPressedAtom = atom(false);
// used by the sidebar to subscribe to editor updates
//   - use the timestamp value, since in the future
//     the sidebar will need to query updates from the server
export const editorUpdateAtom = atom(0);

export const undoDepthAtom = atom(0);
export const redoDepthAtom = atom(0);

const searchBarRefAtom = atom<React.RefObject<HTMLDivElement>>({ current: null });
export const getSearchBarRef = () => getDefaultStore().get(searchBarRefAtom);
export const setSearchBarRef = (ref: React.RefObject<HTMLDivElement>) => getDefaultStore().set(searchBarRefAtom, ref);
export const getSearchBarInput = () => getSearchBarRef().current?.querySelector("input") ?? null;
