import { StoragePayload } from "../nativeIntegration/types";
import appLogger from "../utils/logger";
import { DataStorage } from "./DataStorage";

const logger = appLogger.with({ namespace: "WebViewStorageHandler" });

// Prefix for dates in serialized objects w/ control character to avoid conflicts with other data
const DATE_PREFIX = "DATE\u0001";

export function serialize(obj: any): string {
  function datesToString(value: any): any {
    if (value instanceof Date) {
      return DATE_PREFIX + value.toISOString();
    } else if (Array.isArray(value)) {
      return value.map(datesToString);
    } else if (value && typeof value === "object") {
      const serialized: any = {};
      for (const [k, v] of Object.entries(value)) {
        serialized[k] = datesToString(v);
      }
      return serialized;
    } else {
      return value;
    }
  }
  return JSON.stringify(datesToString(obj));
}

export const deserialize = (serializedObject: string): any => {
  function datestringToDate(value: any): any {
    if (typeof value === "string" && value.startsWith(DATE_PREFIX)) {
      return new Date(value.slice(DATE_PREFIX.length));
    } else if (Array.isArray(value)) {
      return value.map(datestringToDate);
    } else if (value && typeof value === "object") {
      const deserialized: any = {};
      for (const [k, v] of Object.entries(value)) {
        deserialized[k] = datestringToDate(v);
      }
      return deserialized;
    } else {
      return value;
    }
  }
  try {
    const obj = JSON.parse(serializedObject);
    return datestringToDate(obj);
  } catch (e) {
    logger.error("Error deserializing", { error: e, context: { serializedObject } });
    return serializedObject;
  }
};

class WebViewStorageHandler implements DataStorage {
  constructor(
    private nextRequestId = 0,
    private pendingRequests: Map<number, { resolve: (value: any) => void; reject: (reason?: any) => void }> = new Map(),
  ) {}

  async findAllKeys(): Promise<string[]> {
    try {
      const response = (await this.postMessage({ action: "findAllKeys" })) as string[];
      return response;
    } catch (e) {
      logger.error("Error in findAllKeys", { error: e });
      throw e;
    }
  }
  async findAllKeysWithPrefix(prefix: string): Promise<string[]> {
    try {
      const response = (await this.postMessage({ action: "findAllKeysWithPrefix", prefix: prefix })) as string[];
      return response;
    } catch (e) {
      logger.error("Error in findAllKeysWithPrefix", { error: e });
      throw e;
    }
  }

  async getItem<T>(key: string): Promise<T | null> {
    try {
      const response = await this.postMessage({ action: "getItem", key: key });
      if (!response || response.length === 0) {
        return null;
      }
      return deserialize(response) as T;
    } catch (e) {
      logger.error("Error in getItem", { error: e });
      throw e;
    }
  }
  async getItems<T>(keys: string[]): Promise<T[]> {
    try {
      const response = await this.postMessage({ action: "getItems", keys: keys });
      if (!Array.isArray(response)) {
        throw new Error("Expected array response from getItems");
      }
      return response.map((r) => deserialize(r) as T);
    } catch (e) {
      logger.error("Error in getItems", { error: e });
      throw e;
    }
  }

  async setItem(key: string, val: any): Promise<void> {
    try {
      const serialized = serialize(val);
      await this.postMessage({ action: "setItem", key: key, value: serialized });
    } catch (e) {
      logger.error("Error in setItem", { error: e });
      throw e;
    }
  }
  async setItems(keyValues: { key: string; val: any }[]): Promise<void> {
    try {
      const serialized = keyValues.map(({ key, val }) => ({ key, value: serialize(val) }));
      await this.postMessage({ action: "setItems", items: serialized });
    } catch (e) {
      logger.error("Error in setItems", { error: e });
      throw e;
    }
  }

  async removeItem(key: string): Promise<void> {
    try {
      await this.postMessage({ action: "removeItem", key: key });
    } catch (e) {
      logger.error("Error in removeItem", { error: e });
      throw e;
    }
  }
  async removeItems(keys: string[]): Promise<void> {
    try {
      await this.postMessage({ action: "removeItems", keys: keys });
    } catch (e) {
      logger.error("Error in removeItems", { error: e });
      throw e;
    }
  }
  async removeAllItems(): Promise<void> {
    try {
      await this.postMessage({ action: "removeAllItems" });
    } catch (e) {
      logger.error("Error in removeAllItems", { error: e });
      throw e;
    }
  }

  postMessage(messageData: Record<string, any>): Promise<any> {
    const requestId = this.nextRequestId++;

    const promise = new Promise((resolve, reject) => {
      this.pendingRequests.set(requestId, { resolve, reject });
    });

    window.webkit?.messageHandlers.storage.postMessage({ requestId, ...messageData });

    return promise;
  }

  handleStorageResponse(payload: StoragePayload) {
    const { requestId, result } = payload;
    if (!this.pendingRequests.has(requestId)) {
      logger.warn("Received response for unknown request", { context: { response: payload }, report: true });
      return;
    }

    const { resolve, reject } = this.pendingRequests.get(requestId)!;
    if (result.error) {
      reject(result.error);
    } else {
      resolve(result.data);
    }
    this.pendingRequests.delete(requestId);
  }
}

const webViewStorageHandler = new WebViewStorageHandler();
export default webViewStorageHandler;
