import { getDefaultStore, useAtomValue } from "jotai";
import { useMemo } from "react";
import { restApiUrl } from "../utils/environment";
import { accessTokenAtom, requestAccessTokenUpdateAtom } from "../model/atoms";
import { UserApiResource } from "./resources/user";
import { NoteApiResource } from "./resources/notes";
import { TranscriptApiResource } from "./resources/transcript";
import { VersionApiResource } from "./resources/version";
import { LinkApiResource } from "./resources/link";
import { CloudwatchApiResource } from "./resources/cloudwatch";
import { ErrorResponse, FetchWrapper } from "./fetchWrapper";
import { FileApiResource } from "./resources/file";
import { SimonResource } from "./resources/simon";
import { AnalyticsApiResource } from "./resources/analytics";

/**
 * Creates a instance of the REST API client using a token. Does not automatically reads token from the store.
 * Can be used with Service Worker.
 * @param {string|null} accessToken - The access token for authentication. Set to null if authentication is not required.
 * @returns {Object} - An object representing the static instance of the REST API client.
 */
export const StaticApiClient = (accessToken: string | null, onErrorResponse?: (error: ErrorResponse) => void) => {
  return buildApiClient(accessToken, onErrorResponse);
};

const requestTokenOn401Error = (error: ErrorResponse) => {
  if (error.status === 401) {
    getDefaultStore().set(requestAccessTokenUpdateAtom, (v) => v + 1);
  }
};

/**
 * Custom hook that returns an instance of ApiClient. Automatically reads token from the store.
 * @returns {Object} - An object representing the REST API client.
 */
export const useApiClient = () => {
  const accessToken = useAtomValue(accessTokenAtom);
  return useMemo(() => buildApiClient(accessToken, requestTokenOn401Error), [accessToken]);
};

/**
 * Returns an instance of ApiClient. Automatically reads token from the store.
 * @returns {Object} - An object representing the REST API client.
 */
export const ApiClient = () => {
  const accessToken = getDefaultStore().get(accessTokenAtom);
  return buildApiClient(accessToken, requestTokenOn401Error);
};

/**
 * Constructs a REST API client.
 * @param {string|null} accessToken - The access token for authentication. Set to null if authentication is not required.
 * @returns {Object} - An object representing the REST API client.
 */
const buildApiClient = (accessToken: string | null, onErrorResponse?: (error: ErrorResponse) => void) => {
  const client = new FetchWrapper({
    baseUrl: restApiUrl,
    defaultHeaders: {
      "Content-Type": "application/json",
    },
    onErrorResponse,
  });
  if (accessToken) {
    client.addDefaultHeader("Authorization", `Bearer ${accessToken}`);
  } else {
    client.removeDefaultHeader("Authorization");
  }
  return {
    users: UserApiResource(client),
    notes: NoteApiResource(client),
    transcripts: TranscriptApiResource(client),
    versions: VersionApiResource(client),
    links: LinkApiResource(client),
    files: FileApiResource(client),
    simon: SimonResource(client),
    analytics: AnalyticsApiResource(client),
    cloudwatch: CloudwatchApiResource(client),
  };
};
